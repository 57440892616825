/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W 2023 r. Transpol Lider Sp. z o.o. Sp. k. realizował zadanie inwestycyjne pn. „Modernizacja drogi wewnątrzzakładowej - Zakład Produkcyjny w Kruszwicy”."), "\n", React.createElement(_components.p, null, "W ramach modernizacji zostały wykonane następujące działania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Wykonanie znakowania pionowego i poziomego w niezbędnym zakresie"), "\n", React.createElement(_components.li, null, "Przebudowa odcinków drogi / zmiana sposobu użytkowania – wg wskazań na załączonej mapie"), "\n", React.createElement(_components.li, null, "Budowa przepustów pod potrzeby budowy wodociągów"), "\n", React.createElement(_components.li, null, "Adaptacja odwodnienia drogi"), "\n", React.createElement(_components.li, null, "Budowa kanału technologicznego / infrastruktury telekomunikacyjnej – wg zaznaczonego zakresu"), "\n", React.createElement(_components.li, null, "Wykonanie znakowania poziomego (malowania)"), "\n", React.createElement(_components.li, null, "Uwzględnienie ruchu samochodowego na terenie zakładu (we wskazanym terminie będzie na ograniczonym poziomie)"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
